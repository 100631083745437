/* styles/globals.css */

/* Default (Light Theme) */
:root {
    --background-color: #ffffff;
    --text-color: #000000;
    --button-background-color: #000000;
    --button-text-color: #ffffff;
    --button-secondary-background-color: #f5f5f5;
    --button-secondary-text-color: #000000;
  }
  
  /* Dark Theme */
  body.dark-theme {
    --background-color: #333333;
    --text-color: #ffffff;
    --button-background-color: #ffffff;
    --button-text-color: #000000;
    --button-secondary-background-color: #555555;
    --button-secondary-text-color: #ffffff;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
    transition: background-color 0.3s, color 0.3s;
  }
  